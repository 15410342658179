(function ($) {
  'use strict';

  $(window).on('load resize', function(){
    var height=$(".l-header").outerHeight();
    $(".l-main").css("padding-top", height);
  });

  $(document).ready(function(){
    var flg = 'open';
    $('.p-m-topic .hide').click(function(){
      $('.p-m-topic__list').slideToggle('slow');

      if(flg == 'close'){
        flg = 'open';
        $(this).text('[hide]');
      }else{
        flg = 'close';
        $(this).text('[slow]');
      }
    });
  });

  window.onload = function() {
    scroll_effect();

    $(window).scroll(function(){
      scroll_effect();
    });

    function scroll_effect(){
      $('.is-effect-fade').each(function(){
        var elemPos = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > elemPos - windowHeight){
        $(this).addClass('is-effect-fade__animation');
        }
      });
    }
  };

})(jQuery);
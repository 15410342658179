const loader = document.getElementById('is-loader');
window.addEventListener('load', () => {
    loader.classList.add('fadeout');
    setTimeout(() => {
        loader.style.opacity = "0";
        loader.style.pointerEvents = "none";
    }, 100);
}, false);

function hamburger() {
    document.body.classList.toggle('is-body-open');
    document.getElementById('hamb-menu-nav').classList.toggle('is-menu-open');
}
document.getElementById('hamb-open').addEventListener('click', () => {
    hamburger();
});
document.getElementById('hamb-close').addEventListener('click', () => {
    hamburger();
});